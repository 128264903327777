<template>
  <div>
    <div class="page-header" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image: url('img/header.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="content-center brand">
          <img class="n-logo" src="img/logo-min.png" alt="" />
          <h1 class="h1-seo">莲花云服务</h1>
          <h3>专业智慧寺庙解决方案服务商</h3>
        </div>
        <h6 class="category category-absolute">
          由
          <a href="https://www.weyha.com/" target="_blank">
            <img src="img/icon_w.png" class="invision-logo" />
          </a>
          设计开发.
        </h6>
      </div>
    </div>
    <div class="main">
      <div class="section section-images">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="hero-images-container">
                <img src="img/hero-image-1.png" alt="" />
              </div>
              <div class="hero-images-container-1">
                <img src="img/hero-image-2.png" alt="" />
              </div>
              <div class="hero-images-container-2">
                <img src="img/hero-image-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <basic-elements></basic-elements>
    <navigation></navigation>
    <tabs-section></tabs-section>
    <progress-pagination></progress-pagination>
    <notifications></notifications>
    <typography></typography>
    <javascript-components></javascript-components>-->

    <mini-app-section></mini-app-section>
    <backend-section></backend-section>
    <carousel-section></carousel-section>
    <smart-screen-section></smart-screen-section>
    <div class="section">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">财务结算</h2>
            <h5 class="description">
              使用微信商户结算，资金自接进入寺庙商户，资金流程安全合规。
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">服务价格</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <card type="login">
                <div class="team-player">
                  <h4 class="title">免费版</h4>
                  <p class="category text-primary">Free</p>
                  <p class="description">可使用莲花云全部功能。</p>
                  <p class="description">平台按一定比例收手续费。</p>
                  <a class="btn btn-primary btn-icon btn-round"
                    ><i class="now-ui-icons tech_mobile"></i
                  ></a>
                  <a class="btn btn-primary btn-icon btn-round"
                    ><i class="now-ui-icons business_money-coins"></i
                  ></a>
                </div>
              </card>
            </div>
            <div class="col-md-4">
              <card type="login" color="orange">
                <div class="team-player">
                  <h4 class="title">付费版</h4>
                  <p class="category">Standard</p>
                  <p class="description">可使用莲花云全部功能。</p>
                  <p class="description">无手续费</p>
                  <a class="btn btn-neutral btn-icon btn-round"
                    ><i class="now-ui-icons tech_mobile"></i
                  ></a>
                  <a class="btn btn-neutral btn-icon btn-round"
                    ><i class="now-ui-icons ui-2_like"></i
                  ></a>
                </div>
              </card>
            </div>
            <div class="col-md-4">
              <card type="login">
                <div class="team-player">
                  <h4 class="title">定制版</h4>
                  <p class="category text-primary">Customize</p>
                  <p class="description">根据客户需求，定制化开发。</p>
                  <p class="description">可对接其他软件，硬件系统。</p>
                  <a class="btn btn-primary btn-icon btn-round"
                    ><i class="now-ui-icons ui-2_settings-90"></i
                  ></a>
                  <a class="btn btn-primary btn-icon btn-round"
                    ><i class="now-ui-icons design_vector"></i
                  ></a>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="section">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">案例参考</h2>
            <h5 class="description">
              更多案例联系商务经理查看
            </h5>
          </div>
        </div>
        <div class="row">
                <div class="col-md-3">  <img src="img/lotus/img8.png"  /> </div>
                <div class="col-md-3"> <img src="img/lotus/img10.png" alt=""  /></div>
                <div class="col-md-3">  <img src="img/lotus/img9.png" alt=""  /></div>
                <div class="col-md-3">  <img src="img/lotus/img12.png" alt=""  /></div>
        </div>
      </div>
    </div>
       <div class="section">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">定制活动</h2>
            <h5 class="description">
              更多案例联系商务经理查看
            </h5>
          </div>
        </div>
        <div class="row">
                <div class="col-md-3">  <img src="img/lotus/img1.png"  /> </div>
                <div class="col-md-3"> <img src="img/lotus/img2.png" alt=""  /></div>
                <div class="col-md-3">  <img src="img/lotus/img6.png" alt=""  /></div>
                <div class="col-md-3">  <img src="img/lotus/img7.png" alt=""  /></div>
      </div> </div>
    </div>    
<!-- <div class="section">
        <div class="container">
      <div class="row">
        <card class="card-signup" header-classes="text-center" color="orange">
          <template slot="header">
            <h3 class="card-title title-up">申请体验</h3>
          </template>
          <template>
            <fg-input
              class="no-border"
              placeholder="姓名..."
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>

            <fg-input
              class="no-border"
              placeholder="联系电话..."
              addon-left-icon="now-ui-icons text_caps-small"
            >
            </fg-input>

            <fg-input
              class="no-border"
              placeholder="邮箱..."
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
          </template>
          <div class="card-footer text-center">
            <n-button type="neutral" round size="lg">提交</n-button>
          </div>
        </card>
      </div>
    </div>
</div> -->
    <div class="section">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">申请试用/商务合作</h2>
            <h5 class="description">
              商务经理:徐 
            </h5>
             <h5 class="description">
              手机:<a href=”tel://13898440661”>13898440661</a>
            </h5>
              <h5 class="description">
             微信 :13898440661
            </h5>
             <h5 class="description">
             邮箱 :xdxttt@weyha.com
            </h5>
          </div>
        </div>
      </div>
    </div>
    <!-- <signup-form></signup-form>
    <examples-section></examples-section>
    <download-section></download-section> -->
  </div>
</template>
<script>
import { Parallax, Button, Card, FormGroupInput } from "@/components";
// import BasicElements from "./components/BasicElementsSection";
// import Navigation from "./components/Navigation";
// import TabsSection from "./components/Tabs";
// import ProgressPagination from "./components/ProgressPagination";
// import Notifications from "./components/Notifications";
// import Typography from "./components/Typography";
// import JavascriptComponents from "./components/JavascriptComponents";
import CarouselSection from "./components/CarouselSection";
import MiniAppSection from "./components/MiniAppSection";
import SmartScreenSection from "./components/SmartScreenSection";
import BackendSection from "./components/BackendSection";

// import SignupForm from "./components/SignupForm";
// import ExamplesSection from "./components/ExamplesSection";
// import DownloadSection from "./components/DownloadSection";

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    Parallax,
    Card,
    // BasicElements,
    // Navigation,
    // TabsSection,
    // ProgressPagination,
    // Notifications,
    // Typography,
    // JavascriptComponents,
    CarouselSection,
    MiniAppSection,
    BackendSection,
    SmartScreenSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    // DownloadSection,
  },
};
</script>
<style>
.col-wrapper {
  flex-wrap: wrap;
}
</style>
