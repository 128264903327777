<template>
  <div class="section section-nucleo-icons">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h2 class="title">管理后台</h2>
        </div>
        <div class="col-12">
          <tabs
            slot="raw-content"
            tab-content-classes="tab-content-padding text-center tab-content-center"
          >
            <tab-pane class="text-center">
              <template slot="label">
                <i class="now-ui-icons files_paper"></i> 日常项目
              </template>
              <p>可以根据寺庙自身特色，自定义日常功德项目。</p>
            </tab-pane>
            <tab-pane>
              <template slot="label">
                <i class="now-ui-icons ui-1_bell-53"></i> 定期法会
              </template>
              <p>
                配合寺庙法会安排，随时发布法会信息，根据法会类型设置相应的功德项目以及配套表单。
              </p>
            </tab-pane>
            <tab-pane>
              <template slot="label">
                <i class="now-ui-icons ui-1_bell-53"></i> 智慧屏管理
              </template>
              <p>可用根据寺内智慧屏摆放位置,动态配置智慧屏显示项目。</p>
            </tab-pane>
            <tab-pane>
              <template slot="label">
                <i class="now-ui-icons design_bullet-list-67"></i> 订单管理
              </template>
              <p>
                实时查看功德金订单，并一键打印配套表单内容，方便日常运营服务。
              </p>
            </tab-pane>
            <tab-pane>
              <template slot="label">
                <i class="now-ui-icons business_chart-bar-32"></i> 数据大屏
              </template>
              <p>
                显示新增用户，累计功德金，发心次数等数据。实时掌握寺庙运营情况。
              </p>
            </tab-pane>
            <tab-pane>
              <template slot="label">
                <i class="now-ui-icons ui-2_settings-90"></i> 账户管理
              </template>
              <p>
                支持自定义系统角色，创建多个不同权限的账户，后台管理轻松安全。
              </p>
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Tabs, TabPane } from "@/components";

export default {
  components: {
    Tabs,
    TabPane,
  },
};
</script>
<style>
.tab-content.tab-content-padding {
  padding: 20px;
}
.nav-tabs-default {
  justify-content: center;
}
</style>