<template>
  <div class="section section-nucleo-icons">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <h2 class="title">小程序/公众号</h2>
           <h6>基础项目:</h6>
          <p>可根据需求设置栏目，内容，收费项目，排行榜，表单项目。</p>
          <h6>定期法会:</h6>
          <p>根据寺庙需求随时发布法会，用户一键登记办理。</p>
          <h6>分享:</h6>
          <p>
            可以轻松插入公众中。也可以使用微信消息，朋友圈，图片二维码等方式分享。
          </p>
           <h6>扫码体验:</h6>
          <div class="qrcode-container">
            <div class="qrcode">
              <img src="img/qrcode.jpg" />
              <p>小程序</p>
            </div>
            <div class="qrcode">
              <img src="img/mpqrcode.png" />
              <p>公众号</p>
            </div>
          </div>

          <!-- <h6>基础项目:</h6>
          <p>访客预约，居士管理。</p>
          <h6>法会预约:</h6>
          <p>根据寺庙需求随时发布法会，用户一键登记预约。</p>
          <h6>分享:</h6>
          <p>
            可以轻松插入公众中。也可以使用微信消息，朋友圈，图片二维码等方式分享。
          </p> -->
        </div>
        <div class="col-lg-8 col-md-12">
          <div class="shorts-container">
           <img class="shorts-1" src="img/m1.png" />
            <img class="shorts-2" src="img/m2.png" />
            <img class="shorts-3" src="img/m3.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.qrcode-container {
  display: flex;
  justify-content: space-around;
}
.shorts-container {
  position: relative;
  height: 600px;
  margin: 0 auto;
  overflow-x: hidden;
}
.shorts-1 {
  position: absolute;
  z-index: 2;
  height: 600px;
  left: 50%;
  transform: translate(-50%);
  transform-origin: 50%;
}
.shorts-2 {
  height: 500px;
  position: absolute;
  top: 50px;
  transform: perspective(500px) rotateY(10deg);
}

.shorts-3 {
  height: 500px;
  position: absolute;
  top: 50px;
  right: 0px;
  transform: perspective(500px) rotateY(-10deg);
}
.qrcode {
  width: 256px;
  text-align: center;
  img {
    width: 256px;
  }
}
</style>
