<template>
  <div class="section section-nucleo-icons">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 smartscreen-container">
          <img class="smartscreen" src="img/smartscreen.png" />
        </div>
        <div class="col-lg-4 col-md-12">
          <h2 class="title">智慧功德箱</h2>
          <h5 class="description">
            <h6>配置灵活:</h6>
            <p>智慧功德箱可设置任意项目。可以摆放在寺庙内任意位置。</p>
            <h6>资金安全:</h6>
            <p>电子支付，防火防盗。</p>
            <h6>使用简单:</h6>
            <p>无需下载App,打开微信扫码即可。</p>
            <h6>扫码体验:</h6>
            <div class="qrcode-container">
              <div class="qrcode">
                <img src="img/qrcode.jpg" />
              </div>
            </div>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
.smartscreen-container {
  text-align: center;
  display: flex;
  justify-content: center;
  justify-items: center;
}
</style>
