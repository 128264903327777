<template>
  <div class="section" id="carousel">
    <div class="container">
      <div class="row justify-content-center imac-continer">
        <img src="img/imac.png" alt="" class="imac" />
        <el-carousel class="screen" height="100%">
          <el-carousel-item>
            <img class="d-block" src="img/c1.png" alt="First slide" />
            <div class="carousel-caption d-none d-md-block">
              <h5>自定义基础项目</h5>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <img class="d-block" src="img/c3.png" alt="Second slide" />
            <div class="carousel-caption d-none d-md-block">
              <h5>定期发布法会</h5>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <img class="d-block" src="img/c2.png" alt="Third slide" />
            <div class="carousel-caption d-none d-md-block">
              <h5>订单管理</h5>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <img class="d-block" src="img/c4.png" alt="Third slide" />
            <div class="carousel-caption d-none d-md-block">
              <h5>表单打印</h5>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <img class="d-block" src="img/c5.png" alt="Third slide" />
            <div class="carousel-caption d-none d-md-block">
              <h5>数据大屏</h5>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, CarouselItem } from "element-ui";

export default {
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },
};
</script>
<style>
.imac-continer {
  position: relative;
}
.imac {
  width: 100%;
}
.screen {
  position: absolute;
  width: 95%;
  margin-top: 3%;
  height: 95%;
}
</style>
