<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link class="navbar-brand" to="/">
        <img class="logo" src="img/logo.png" />
      </router-link>
    </template>
    <template slot="navbar-menu">
      <!-- <li class="nav-item">
        <a
          class="nav-link"
          href="https://www.creative-tim.com/product/vue-now-ui-kit"
          target="_blank"
        >
          <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          <p>Download</p>
        </a>
      </li> -->
      <!-- <drop-down
        tag="li"
        icon="now-ui-icons design_app"
        class="nav-item"
      >
        <nav-link to="/miniapp">
          <i class="now-ui-icons business_chart-pie-36"></i>小程序/公众号
        </nav-link>
        <nav-link to="/backend">
          <i class="now-ui-icons business_chart-pie-36"></i>管理后台
        </nav-link>
        <nav-link to="/smartscreen">
          <i class="now-ui-icons business_chart-pie-36"></i>智慧功德箱
        </nav-link>
        <nav-link to="/finance">
          <i class="now-ui-icons business_chart-pie-36"></i>财务管理
        </nav-link>
        <nav-link to="/price">
          <i class="now-ui-icons business_chart-pie-36"></i>价格
        </nav-link>
      </drop-down> -->
      <!-- <li class="nav-item">
        <a
          class="nav-link btn btn-neutral"
          href="https://www.creative-tim.com/product/vue-now-ui-kit-pro"
          target="_blank"
        >
          <i class="now-ui-icons arrows-1_share-66"></i>
          <p>Upgrade to PRO</p>
        </a>
      </li> -->

      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="联系电话"
          data-placement="bottom"
          href="tel:13898440661"
          target="_blank"
        >
          <i class="now-ui-icons tech_mobile"></i>
          <p class="d-lg-none d-xl-none">联系电话</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="商务邮件"
          data-placement="bottom"
          href="mailto:xdxttt@weyha.com"
          target="_blank"
        >
          <i class="now-ui-icons ui-1_email-85"></i>
          <p class="d-lg-none d-xl-none">商务邮件</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from "@/components";
import { Popover } from "element-ui";
export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    Navbar,
    [Popover.name]: Popover,
  },
};
</script>

<style scoped>
.logo {
  height: 28px;
}
</style>
